export const API_AUTH_LOGIN = "/auth/admin-login";
export const API_AUTH_LOGOUT = "/auth/logout/";

// categories apis
export const API_CATEGORY_LIST = "/categories";
export const API_CATEGORY_CREATE = "/categories";
export const API_CATEGORY_UPDATE = "/categories-update";

// resource controller apis
export const API_ORDERS = "/orders";
export const API_ORDER_UPDATE_STATUS = "/order-status-update";
export const API_CATEGORY = "/categories";
export const API_Schedule_charges = "/schedule_charges";
export const API_SCHEDULE_HOLD_TOGGLE = "/schedule-hold-toggle";
export const API_CATEGORY_HOLD_TOGGLE = "/category-hold-toggle";
export const API_RESOURCE_SHOP = "/shops";
export const API_SHOP_HOLD_TOGGLE = "/shop-hold-toggle";
export const API_RESOURCE_PRODUCT = "/products";
export const API_PRODUCT_HOLD_TOGGLE = "/product-hold-toggle";
export const API_All_SHOPS = "/shops-list";
export const API_SLIDERS = "/home-sliders";
export const API_HOME_SECTIONS = "/home-sections";
export const API_ADMIN_SETUP = "/admin-setup";
export const API_SLIDERS_UPDATE = "/home-sliders-update";
