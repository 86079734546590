// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup/NavGroup";

import adminDashboard from "src/menu-items/AdminDashboard";
import categoriesMenu from "src/menu-items/categories";
import shopMenu from "src/menu-items/shopMenu";
import HomeSliderMenu from "src/menu-items/HomeSliderMenu";
import SetupMenu from "src/menu-items/SetupMenu";
import HomeSectionMenu from "src/menu-items/HomeSectionMenu";
import ScheduleChargeMenu from "src/menu-items/ScheduleChargeMenu";
import { useEffect, useState } from "react";
import axiosInstance from "src/utils/CustomAxios";
import { IconKey, IconBook, IconShoppingCart } from "@tabler/icons-react";
import NavItem from "./NavItem/NavItem";
import config from "src/config";
import NavCollapse from "./NavCollapse/NavCollapse";
// constant
const icons = {
  IconKey,
  IconShoppingCart,
};

// ==============================|| SIDEBAR MENU LIST ||============================== //
// Function to fetch categories and products
const fetchCategoriesAndProducts = async () => {
  try {
    const response = await axiosInstance.get("/categories-menu"); // Adjust the endpoint accordingly
    return response.data;
  } catch (error) {
    console.error("Error fetching categories and products", error);
    return [];
  }
};
const MenuList = ({ AuthRole }) => {
  const [menuItems, setMenuItems] = useState([]);
  const OrdersMenu = {
    id: "OrdersMenu",
    title: "orders",
    type: "item",
    url: "/portal/admin/orders",
    icon: icons.IconShoppingCart,
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchCategoriesAndProducts();
      const { categories } = await data;
      // Construct the "Products" parent item
      const productMenu = {
        id: "Category Products",
        title: "Category Base Products",
        type: "collapse",
        icon: IconBook,
        children: categories.map((category) => ({
          id: `category-${category.id}`,
          title: category.category_name,
          url: `/portal/admin/products/${category.id}/${category.category_name
            .replace(/\s+/g, "-") // Replace spaces with hyphens
            .replace(/\//g, "-") // Replace slashes with hyphens
            .toLowerCase()}`,
          icon: icons.IconKey,
          icon_is_img: `${config.apiURL}/${category.category_image}`,
          type: "item", // Use 'item' for direct navigation
        })),
      };

      // admin and sub admin

      if (AuthRole === 0 || AuthRole === 1) {
        setMenuItems([
          adminDashboard,
          OrdersMenu,
          categoriesMenu,
          shopMenu,
          productMenu,
          // HomeSliderMenu,
          ScheduleChargeMenu,
          HomeSectionMenu,
          SetupMenu,
        ]);
      }
    };

    fetchData();
  }, [AuthRole]);
  const navItems = menuItems.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={1} />;
      case "item":
        return <NavItem key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
