import NavigationScroll from "./layout/NavigationScroll";
import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import AllRoutes from "./routes/AllRoutes";
import Theme from "./themes/Theme";
import { AuthProvider } from "./context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
function App() {
  const customization = useSelector((state) => state.customization);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={Theme(customization)}>
        <AuthProvider>
          <CssBaseline />
          <NavigationScroll>
            <ToastContainer />
            <AllRoutes />
          </NavigationScroll>
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
