const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs,
  //also Don't put only '/' use blank('') instead,
  basename: "",
  defaultPath: "/",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  apiBaseURL: `https://backend.rayahenmarket.com/api`,
  apiURL: `https://backend.rayahenmarket.com`,
};
export default config;
// const config = {
//   basename: process.env.REACT_APP_BASENAME || "",
//   defaultPath: process.env.REACT_APP_DEFAULT_PATH || "/",
//   fontFamily: process.env.REACT_APP_FONT_FAMILY || `'Roboto', sans-serif`,
//   borderRadius: process.env.REACT_APP_BORDER_RADIUS || 12,
//   apiBaseURL: process.env.REACT_APP_API_BASE_URL || `http://127.0.0.1:8000/api`,
//   apiURL: process.env.REACT_APP_API_URL || `http://127.0.0.1:8000`,
// };

// export default config;
