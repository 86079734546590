// assets
import { IconDashboard } from "@tabler/icons-react";

// constant
const icons = { IconDashboard };
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const adminDashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "item",
  url: "/portal/admin/dashboard",
  icon: icons.IconDashboard,
};

export default adminDashboard;
