import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// material-ui
import { ButtonBase } from "@mui/material";
// project imports
import config from "src/config";
import { MENU_OPEN } from "src/store/actions";
import logo from "src/assets/abeer.png";
const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  return (
    <ButtonBase
      disableRipple
      onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })}
      component={Link}
      to={config.defaultPath}
    >
      <img src={logo} alt="abeer" width="100" />
    </ButtonBase>
  );
};

export default LogoSection;
