import PortalLayout from "src/layout/PortalLayout";
import Loadable from "src/components/Loadable";
import { lazy } from "react";
// import { useAuthRole } from "src/hooks/useAuthRole";

const AdminDashboard = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/Dashboard/AdminDashboard"))
);
// category crud
const CategoryList = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/Categories/CategoryList"))
);
const CategoryCreate = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/Categories/CategoryCreate"))
);
const CategoryEdit = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/Categories/CategoryEdit"))
);
// schedule charges crud
const ScheduleChargeList = Loadable(
  lazy(() =>
    import("src/Container/ADMIN-PORTAL/ScheduleCharge/ScheduleChargeList")
  )
);
const ScheduleChargeCreate = Loadable(
  lazy(() =>
    import("src/Container/ADMIN-PORTAL/ScheduleCharge/ScheduleChargeCreate")
  )
);
const ScheduleChargeEdit = Loadable(
  lazy(() =>
    import("src/Container/ADMIN-PORTAL/ScheduleCharge/ScheduleChargeEdit")
  )
);
// shop crud
const ShopList = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/shops/ShopList"))
);
const ShopCreate = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/shops/ShopCreate"))
);
const ShopEdit = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/shops/ShopEdit"))
);
const SliderSetup = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/SliderSetup/SliderSetup"))
);
// shop crud
const ProductList = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/products/ProductList"))
);
const ProductCreate = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/products/ProductCreate"))
);
const ProductEdit = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/products/ProductEdit"))
);
const OrdersList = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/orders/OrdersList"))
);
// shop crud
const HomeSectionList = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/HomeSection/HomeSectionList"))
);
const HomeSectionCreate = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/HomeSection/HomeSectionCreate"))
);
const HomeSectionEdit = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/HomeSection/HomeSectionEdit"))
);
const MainSetup = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/MainSetup/MainSetup"))
);

const AdminRoutes = () => {
  // Function to conditionally include "sub-admins" section based on user role
  function useSubAdminRoutes() {
    // const isSubAdmin = useAuthRole(0); // Assuming 1 is the role ID for sub-admins
    // if (isSubAdmin) {
    //   return {
    //     path: "sub-admins",
    //     children: [
    //       { path: "", element: <SubAdminList /> },
    //       { path: "create", element: <SubAdminCreate /> },
    //       { path: "edit/:SubAdminId", element: <SubAdminEdit /> },
    //     ],
    //   };
    // }
    return null; // Return null if user is not a sub-admin
  }
  // Call the function to get the sub-admin routes
  const subAdminRoutes = useSubAdminRoutes();
  return {
    path: "portal/admin",
    element: <PortalLayout />,
    children: [
      {
        path: "dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "categories",
        children: [
          { path: "", element: <CategoryList /> },
          { path: "create", element: <CategoryCreate /> },
          { path: "edit/:CategoryID", element: <CategoryEdit /> },
        ],
      },
      {
        path: "shops",
        children: [
          { path: "", element: <ShopList /> },
          { path: "create", element: <ShopCreate /> },
          { path: "edit/:ShopID", element: <ShopEdit /> },
        ],
      },

      {
        path: "orders",
        children: [{ path: "", element: <OrdersList /> }],
      },
      {
        path: "products",
        children: [
          { path: ":CategoryID/:CategoryName", element: <ProductList /> },
          {
            path: ":CategoryID/:CategoryName/create",
            element: <ProductCreate />,
          },
          {
            path: ":CategoryID/:CategoryName/edit/:productID",
            element: <ProductEdit />,
          },
        ],
      },
      {
        path: "schedule-charges",
        children: [
          { path: "", element: <ScheduleChargeList /> },
          { path: "create", element: <ScheduleChargeCreate /> },
          { path: "edit/:ScheduleId", element: <ScheduleChargeEdit /> },
        ],
      },
      {
        path: "HomeSection",
        children: [
          { path: "", element: <HomeSectionList /> },
          { path: "create", element: <HomeSectionCreate /> },
          { path: "edit/:SectionId", element: <HomeSectionEdit /> },
        ],
      },
      {
        path: "SliderSetup",
        children: [{ path: "", element: <SliderSetup /> }],
      },
      {
        path: "main-setup",
        children: [{ path: "", element: <MainSetup /> }],
      },
      // Include the sub-admin routes if available
      subAdminRoutes,
    ].filter(Boolean),
  };
};

export default AdminRoutes;
