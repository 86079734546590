// assets
import { IconBuildingStore } from "@tabler/icons-react";

// constant
const icons = {
  IconBuildingStore,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const shopMenu = {
  id: "shops",
  title: "Shops",
  type: "item",
  url: "/portal/admin/shops",
  icon: icons.IconBuildingStore,
};

export default shopMenu;
