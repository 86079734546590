// assets
import { IconDashboard } from "@tabler/icons-react";

// constant
const icons = { IconDashboard };
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const ScheduleChargeMenu = {
  id: "ScheduleChargeMenu",
  title: "Schedule Charges",
  type: "item",
  url: "/portal/admin/schedule-charges",
  icon: icons.IconDashboard,
};

export default ScheduleChargeMenu;
