import { Navigate, useRoutes } from "react-router-dom";
import AuthRoutes from "./ROUTE-PATHS/AuthRoutes";
import AdminRoutes from "./ROUTE-PATHS/adminRoutes";
import { useAuthRole } from "src/hooks/useAuthRole";
export default function AllRoutes() {
  const is_admin = useAuthRole(0);
  // console.log(is_admin);
  // Define routes based on user roles
  const routes = [];

  // Show admin routes if user is an admin
  if (is_admin) {
    const adminRouteConfig = AdminRoutes(); // Invoke AdminRoutes to get the route configuration
    routes.push(adminRouteConfig);
  } else {
    routes.push(AuthRoutes);
  }
  if (is_admin) {
    // Add a fallback route to handle unmatched paths
    routes.push({
      path: "*",
      element: <Navigate to="/portal/admin/dashboard" replace />, // Redirect to login or home screen
    });
  } else {
    // Add a fallback route to handle unmatched paths
    routes.push({
      path: "*",
      element: <Navigate to="/" replace />, // Redirect to login or home screen
    });
  }

  // Return routes using useRoutes hook
  return useRoutes(routes);
}
