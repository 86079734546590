// assets
import { IconDashboard } from "@tabler/icons-react";

// constant
const icons = { IconDashboard };
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const HomeSectionMenu = {
  id: "HomeSectionMenu",
  title: "Home Sections",
  type: "item",
  url: "/portal/admin/HomeSection",
  icon: icons.IconDashboard,
};

export default HomeSectionMenu;
