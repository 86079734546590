// assets
import { IconDashboard } from "@tabler/icons-react";

// constant
const icons = { IconDashboard };
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const SetupMenu = {
  id: "SetupMenu",
  title: "Main Setup",
  type: "item",
  url: "/portal/admin/main-setup",
  icon: icons.IconDashboard,
};

export default SetupMenu;
